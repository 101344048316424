html, body {
  padding: 0;
  margin: 0;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
}

body > #root > div {
  min-height: 100vh;
  height: 100%;
  position: relative;
}

body {
  margin: 0;
   font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  overflow-x: hidden;
}

.App {
  /*padding-top: 10px;
  padding-right: 35px;*/
  background-color: #000;
}

.header-container {
  text-align: center;
}

.logo {
  display: inline-block;
  box-sizing: border-box;
  width: 70px;
  aspect-ratio: 1;
  border: 13px solid #000;
  background: radial-gradient(farthest-side,#fff 85%,#000) top, radial-gradient(farthest-side,#fff 85%,#000) top, radial-gradient(farthest-side,#fff 85%,#000) left, radial-gradient(farthest-side,#fff 85%,#000) right, radial-gradient(farthest-side,#fff 85%,#000) bottom, #000;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  filter: blur(4px) contrast(10);
  top: 18px;
  position: relative;
  z-index: 0;
}
.logo.logo-animation {
  animation: logo 2s infinite;
}
@keyframes logo {
  0%   {background-position:top,top,left,right,bottom;}
  25%  {background-position:right,top,left,right,bottom;}
  50%  {background-position:bottom,top,left,right,bottom;}
  75%  {background-position:left,top,left,right,bottom;}
  100% {background-position:top,top,left,right,bottom;}
}

h1 {
  text-align: center;
  font-size: 33px;
  color: #eee;
  display: inline-block;
  margin-bottom: 3px;
  z-index: 1;
  position: relative;
  font-weight: bold;
  margin-top: 33px;
  vertical-align: top;
  left: 5px;
}

h3 {
  color: #8D8D8D;
  font-weight: 400;
  margin: 3px 0 30px 0;
  z-index: 3;
  position: relative;
  font-size: 16px;
}

.input-containter {
    width: 40%;
    margin: 0px 30%;
    position: relative;
}

.input-containter textarea {
  border-radius: 10px;
  padding: 0 110px 0 50px;
  font-size: 14px;
  height: 42px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  font-family: 'MONTSERRAT';
  border: 0;
  line-height: 42px;
}

.input-containter textarea.input-scroll-2 {
  line-height: 30px;
  height: 60px;
}

.input-containter textarea.input-scroll-3 {
  line-height: 30px;
  height: 90px;
}

.input-containter input.padding-fix {
  padding-right: 50px;
}

.input-containter .magnifying-glass{
  height: 20px;
  position: absolute;
  z-index: 1;
  margin-left: 15px;

  top: 46%;
  transform: translateY(-50%);
}

.input-containter .clean-input {
  height: 18px;
  position: absolute;
  z-index: 1;
  margin-top: 3px;
  right: 11px;
  cursor: pointer;
  padding: 10px;

  top: 40%;
  transform: translateY(-50%);
}

.button {
  position: absolute;
  width: 95px;
  height: 34px;
  border-radius: 10px;
  vertical-align: middle;
  line-height: 34px;
  font-size: 16px;
  cursor: pointer;
  color: white;
  text-align: center;
  background-color: #00A3FF;
  display: inline-block;
  right: 5px;
  z-index: 10;
  user-select: none;
  font-weight: 700;
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
}

.hide-search {display: none;}

.button.disable-search {background-color: lightblue !important;}

.placeholder-facts{
  text-align: left;
  padding-top: 29px;
  width: 40%;
  margin: 0px 30%;
}
.placehodlder-text{
  color: #eee;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  margin-top: 24px;
  padding-left: 12px;
  cursor: pointer;
}
.placehodlder-stat{
  color: #F68787;
  font-weight: 700;
  font-size: 23px;  
  line-height: 26.95px;
  padding-left: 10px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}


.fact-timestamp {
  color: #e0e0e0;
  font-size: 14px;
  line-height: 24px;
  margin-top: 5px;
}

.fact-container {
  min-height: 100px;
  padding: 20px 30% 10px 30%;
  line-height: 24px;
  font-size: 16px;
  color: whitesmoke;
  white-space: pre-line;
}

.fact-container .fact-sum {
  font-size: 14px;
}

.fact-title {
  font-weight: bold;
}

.source-container {
  color: white;
  padding: 15px 30% 95px;
}

.fact-x {
  font-weight: 600;
}

.fact-sum {
  font-weight: 400;
}

.fact-sources-seperator {
  height: 1px;
  background-color: #4F4F4F;
  margin-bottom: 20px;
}

.fact-sources {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2px;
}

.fact-card {
  width: 100%;
  word-wrap: break-word;
  box-sizing: border-box;
  margin-bottom: 20px;
  position:relative;
}

.fact-image {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  vertical-align: top;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.fact-content {
  display: inline-block;
  width: calc(100% - 42px);
  margin-left: 42px;;
}

.fact-card-title {
  color: #BDBDBD;
  text-decoration: none;
  font-weight: 800;
  font-size: 12px;
}

.fact-card-content {
  font-size: 14px;
  text-decoration: none;
  color: whitesmoke;
}

.icons-container-bottom {
  text-align: left;
}

.icons-container-top {
  position: absolute;
  right: 30%;
  margin-top: -55px;
}

.copy-button {
  width: 25px;
  padding-right: 15px;
  cursor: pointer;
}

.copy-button.twitter-button {
  height: 16px;
  width: 19px;
  margin-bottom: 3px;
}

.copy-button.copy-clip {
  margin-bottom: -2px;
}

.copy-button.share-url { 
  margin-bottom: -2px;
}

.responseStyle {
  margin: 15px 50%;
  position: absolute;
  left: 22%;
  width: 100px;
}

.responseStyle img {
  cursor: pointer;
  height: 50px;
}

.footer {
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  bottom: 35px;
  position: absolute;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  h1 {
    margin-top: 30px;
    left: -10px;
  }
  .input-containter {
    width: 86%;
    margin: 0px 7%;
  }
  .placeholder-facts {
    width: 86%;
    margin: 0px 7%;
  }
  .input-containter textarea {
    width: 100%;
    margin: 0;
    font-size: 15px;
  }
  .App {
    padding-top: 10px;
    padding-right: 0;
  }
  .logo {
    left: -10px;
  }
  .fact-container {
    padding: 20px 7% 50px;
    line-height: 28px;
    font-size: 15px;
  }
  .responseStyle {
    left: 20%;
  }
  .source-container{
    padding: 0 7% 95px;
    margin-top: -30px;
  }
  .fact-card {
    width: 100%;
    margin-bottom: 10px;
  }
  .icons-container-top {
    right: 7%;
    margin-top: -64px;
  }
  .footer {
    font-size: 10px;
  }
}