.status {
    position: relative;
    height: 46px;
}
.status-dot { 
    border-radius: 50%;
    height:12px;
    width:12px;
    position: absolute;
    top: 17px;
    left: 17px;
}

.status-negative .status-dot { 
    background-color: #EB5757;
}

.status-positive .status-dot { 
    background-color: #219653;
}

.status-nutral .status-dot { 
    background-color: #F8B22B;
}

.status-other .status-dot { 
    background-color: #BDBDBD;
}

.status-doughnut { 
    border-radius: 50%;
    height: 26px;
    width: 26px;
    box-shadow: 0px 0px 4px 0px #00000040;
    z-index: 2;
    position: absolute;
}

.status-negative .status-doughnut { 
    border: 10px solid #F68787;
}

.status-positive .status-doughnut { 
    border: 10px solid #48B375;
}

.status-nutral .status-doughnut { 
    border: 10px solid #FAC55D;
}

.status-other .status-doughnut { 
    border: 10px solid #E0E0E0;
}

.status-label { 
    height: 30px;
    border-radius: 0 6px 6px 0;
    position: absolute;
    top: 8px;
    left: 37px;
    padding: 0 25px;
    z-index: 1;
    font-size: 23px;
    line-height: 30px;
    min-width: 63px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.status-negative .status-label { 
    background: #EB5757;
}

.status-positive .status-label { 
    background: #219653;
}

.status-nutral .status-label { 
    background-color: #F8B22B;
    color: black;
}

.status-other .status-label { 
    background-color: #BDBDBD;
    color: black;
}
